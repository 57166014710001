<template>
  <v-container class="px-0" fluid>
    <v-container>
      <v-row class="d-flex align-center justify-center header">
        <v-col cols="auto">
          <h1 class="header__title">{{ jam.name }}</h1>
          <h5>Jam Phone Number: {{ phone }}</h5>
        </v-col>
      </v-row>
    </v-container>
    <v-banner v-if="$route.path === `/jam/${ this.$route.params.id}/jammers`" class="banner mb-1" color="secondary">
      <div class="animation-min-height">
        <v-slide-x-transition>
          <span v-show="showBannerText" class="banner__text">{{ $t('jammers.inviteYourJammers') }}</span>
        </v-slide-x-transition>
      </div>
    </v-banner>
    <v-container v-intersect.once="onIntersect" class="px-15" fluid>
      <v-row>
        <v-col class="left-container" cols="4">
          <JamMenu class="d-none d-lg-block" @menuClick="handleClick" />
        </v-col>
        <v-col class="px-0" lg="7" cols="12">
          <router-view
              @menuClick="handleClick"
              :jam="jam"
              @notify="handleNotify"
          />
        </v-col>
      </v-row>
      <v-dialog
          v-model="dialogConfirmDelete"
          max-width="450"
      >
        <v-card>
          <v-card-title>Delete Confirm</v-card-title>
          <v-card-text>
            <v-switch label="Delete Everything" @change="onEverythingToggle" v-model="toDelete.jam" />
            This will release the phone number, delete all assets and then delete the jam.
          </v-card-text>
          <v-card-text>
            <v-switch refs="togAssets" label="Delete All Assets" v-model="toDelete.assets" />
            <v-switch refs="togNumber" label="Release Phone Number" v-model="toDelete.number" />
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn>Cancel</v-btn>
            <v-btn color="primary" @click="onDelete">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogProgress" max-width="450" persistent>
        <ProgressDialog
            :text="progressText"
        />
      </v-dialog>
    </v-container>
    <v-snackbar
        color="success"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <!--        <v-icon large class="pr-3">mdi-check-circle</v-icon>-->
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
    <v-dialog v-model="dialogFinishJam" width="400">
      <v-card>
        <v-card-title>
          Finish Your Jam?
        </v-card-title>
        <v-card-text>
          Your jam ended on {{ jam.endDate }} and is still active. Would you like to finish your jam now?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="onFinish">
            Finish Jam
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStartJam" width="400">
      <v-card>
        <v-card-title>
          Start Your Jam
        </v-card-title>
        <v-card-text>
          Your jam start date is {{ jam.endDate }}. To start you jam you need to confirm your prompts.
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="onConfirm">
            Confirm prompts now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCreateBook" width="400">
      <v-card>
        <v-card-title>
          Create Your Book
        </v-card-title>
        <v-card-text>
          Now that your jam is finished would you like to create your book?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="onCreate">Create book</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogThreeOptions" width="500">
      <v-card>
        <v-card-title>
          It's been a minute!
        </v-card-title>
        <v-card-text>
          It has been over a day since your last prompt. What would you like to do now?
        </v-card-text>
        <v-card-text>
          <v-btn color="primary" large block @click="onAddPrompt">
            Add another prompt to keep things going
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-btn block large color="primary" @click="onAddLastPrompt">
            prompt your jammers for one last chance to send things to your jam
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-btn block large color="primary" @click="onFinishJam">
            finish your jam and create your book
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="progress.visible" max-width="450" persistent>
      <ProgressDialog
          :text="progress.text"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import JamMenu from '../components/JamMenu';
import Http from '@/api/Http';
import { getFormattedPhone } from '@/lib';
import { ProgressDialog } from '@/components/dialogs';

export default {
  name: 'Account',
  components: {
    JamMenu,
    ProgressDialog
  },
  data() {
    return {
      showBannerText: false,
      api: Http.use('data'),
      jam: {},
      dialogConfirmDelete: false,
      dialogProgress: false,
      dialogFinishJam: false,
      dialogCreateBook: false,
      dialogStartJam: false,
      dialogThreeOptions: false,
      progressText: '',
      toDelete: {
        jam: false,
        assets: false,
        number: false
      },
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      },
      progress: {
        visible: false,
        text: ''
      },
    }
  },
  computed: {
    phone() {
      return getFormattedPhone(this.jam.phoneNumber);
    }
  },
  created() {
    this.loadJam();
  },
  methods: {
    onCreate() {
      this.progress = {
        visible: true,
        text: 'Creating book...'
      }
      this.api.post(`/jams/${this.$route.params.id}/createBook`)
          .then((book) => {
            this.progress.text = 'Loading book...'
            window.location.href = `/editor/${this.$route.params.id}/${book._id}`;
            this.progress.visible = false;
          })
          .catch(err => {
            console.log(err);
            this.progress.visible = false;
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error ? err.response.data.error : err.message,
              icon: 'mdi-alert-circle-outline'
            }
          });
    },
    onFinish() {
      this.api.patch(`/jams/${this.$route.params.id}`, { status: 'complete'})
          .then(() => {
            this.dialogFinishJam = false;
            // this.loadJam();
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Your jam is now finished.',
              icon: 'mdi-check-circle'
            }
            this.dialogCreateBook = true;
          })
          .catch(err => {
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error ? err.response.data.error : err.message,
              icon: 'mdi-alert-circle-outline'
            }
          })
    },
    loadJam() {
      this.api.get('/jams/' + this.$route.params.id)
          .then(jam => {
            this.jam = jam;
            if (jam.action !== undefined) {
              if (jam.action === 'finishJam') {
                this.dialogFinishJam = true;
              } else if (jam.action === 'startJam') {
                this.dialogStartJam = true;
              } else if (jam.action === 'doSomething' && this.$route.query.act === undefined) {
                this.dialogThreeOptions = true;
              } else if (jam.action === 'createBook') {
                this.dialogCreateBook = true;
              }
            }
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.$router.push('/jam/?404=true');
            } else {
              console.log(err);
            }
          })
    },
    onConfirm() {
      if (this.$route.path !== `/jam/${this.$route.params.id}/prompts`) {
        this.$router.push(`/jam/${this.$route.params.id}/prompts`);
      }
      this.dialogStartJam = false;
    },
    onEverythingToggle(evt) {
      this.toDelete.assets = evt;
      this.toDelete.number = evt;
    },
    handleNotify(wht) {
      this.notify = wht
    },
    handleClick(w) {
      if (w === 'delete') {
        this.dialogConfirmDelete = true;
      } else {
        if (this.$route.path !== '/jam/' + this.$route.params.id + '/' + w) {
          this.$router.push('/jam/' + this.$route.params.id + '/' + w);
        }
      }
    },
    onIntersect(entries) {
      let vm = this
      setTimeout(function() {
        vm.showBannerText = entries[0].isIntersecting;
      }, 500);
    },
    onAddPrompt() {
      this.dialogThreeOptions = false;
      this.$router.push('/jam/' + this.$route.params.id + '/prompts?act=new');
    },
    onAddLastPrompt() {
      this.dialogThreeOptions = false;
      this.$router.push('/jam/' + this.$route.params.id + '/prompts?act=last');
    },
    onFinishJam() {
      this.dialogThreeOptions = false;
      this.onFinish();
    },
    async onDelete() {
      this.dialogConfirmDelete = false;
      this.dialogProgress = true;
      try {
        if ((this.toDelete.jam || this.toDelete.number) && this.jam.phoneNumber !== undefined) {
          this.progressText = 'Releasing phone number';
          await this.api.delete('/sms/number', {jamId: this.$route.params.id })
              .catch(err => {
                this.dialogProgress = false;
                if (err.response.data.error) {
                  throw new Error(err.response.data.error);
                } else {
                  throw err;
                }
              });
        }

        if (this.toDelete.jam || this.toDelete.assets) {
          this.progressText = 'Deleting all assets';
          await this.api.delete(`/jams/${this.$route.params.id}/assets`)
              .catch(err => {
                this.dialogProgress = false;
                if (err.response.data.error) {
                  throw new Error(err.response.data.error);
                } else {
                  throw err;
                }
              });
        }

        if (this.toDelete.jam) {
          this.progressText = 'Deleting the jam';
          await this.api.delete(`/jams/${this.$route.params.id}`)
              .catch(err => {
                this.dialogProgress = false;
                if (err.response.data.error) {
                  throw new Error(err.response.data.error);
                } else {
                  throw err;
                }
              });
        }

        this.dialogProgress = false;
        this.notify = {
          visible: true,
          color: 'success',
          text: 'Delete completed',
          icon: 'mdi-check-circle'
        }
        if (this.toDelete.jam) {
          this.$router.push('/jams');
        }
      } catch (err) {
        this.notify = {
          visible: true,
          color: 'red accent-2',
          text: err.message,
          icon: 'mdi-alert-circle-outline'
        }
      }
    }
  }
}
</script>

<style scoped>
.snack-text {
  font-size: 1.6rem;
  text-align: center;
  display: block;
  margin-top: 3px;
  color: #ffffff;
}
.animation-min-height {
  min-height: 2rem;
}
.v-tab--active.tab__text:after {
  content: "";
  background: var(--v-text-base);
  border-radius: 1rem;
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header {
  margin-bottom: .5rem;
}
.header__title {
  font-size: 5rem;
  color: var(--v-primary-base);
}
.banner >>> .v-banner__content {
  padding-top: .5rem;
}
.banner {
  text-align: center;
}
.banner__text {
  font-size: 2.75rem;
  color: white;
  font-weight: bold;
}
.steps {
  font-size: 2rem;
}
.tab__text {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0;
  margin-right: 6.25rem;
  padding-bottom: 10px;
}
.left-container {
  margin-right: 4rem;
}
.theme--light.v-tabs >>> .v-tab:not(.v-tab--active) {
  font-weight: 400;
  color: var(--v-text-base);
}

@media only screen and (max-width: 1264px) {
  .header {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .banner__text {
    font-size: 1.5rem;
  }
  .banner >>> .v-banner__wrapper {
    padding: .5rem
  }
  .tab__text {
    font-size: 1.5rem;
    margin-right: 3.5rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1264px) {
  .header__title {
    font-size: 4.6rem;
  }
}
@media only screen and (max-width: 600px) {
  .header__title {
    font-size: 2rem;
  }
}
</style>
